import React from "react";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const CreateReactAppEntryPoint = dynamic(() => import("../App"), {
  ssr: false,
});

Sentry.init({
  dsn: "https://2e8e484fe5ab4ffb8b3e53a54668a8c8@o1192704.ingest.sentry.io/6314355",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
// We are mounting a Create React App  (basically) at the index page.
// We have a hash router within this app to do hash routing.
// Expectation is the usual nextjs pages should still work for paths prior to
// the hash.
function App() {
  return <CreateReactAppEntryPoint />;
}

export default App;
